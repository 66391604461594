import React from "react";
import Carousel from "react-bootstrap/Carousel";
function CarouselBlog() {
  return (
    <Carousel controls={false}>
      <Carousel.Item>
        <div className="slide-content">
          <h6 className="font-family-monts">updates</h6>
          <h1>Screaming at streaming prices</h1>

          <div className="wrapper" id="wrapper">
            <p className="activeText font-family-lato">
              Stay informed about the latest price hikes for streaming services
              like Netflix, Hulu, and Amazon Prime. Our blog offers insights
              into the reasons behind these increases and provides tips on how
              to save money while still enjoying your favorite shows and movies.
            </p>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="slide-content">
          <h6 className="font-family-monts">updates</h6>
          <h1>Screaming at streaming prices</h1>

          <div className="wrapper" id="wrapper">
            <p className="activeText font-family-lato">
              Stay informed about the latest price hikes for streaming services
              like Netflix, Hulu, and Amazon Prime. Our blog offers insights
              into the reasons behind these increases and provides tips on how
              to save money while still enjoying your favorite shows and movies.
            </p>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="slide-content">
          <h6 className="font-family-monts">updates</h6>
          <h1>Screaming at streaming prices</h1>

          <div className="wrapper" id="wrapper">
            <p className="activeText font-family-lato">
              Stay informed about the latest price hikes for streaming services
              like Netflix, Hulu, and Amazon Prime. Our blog offers insights
              into the reasons behind these increases and provides tips on how
              to save money while still enjoying your favorite shows and movies.
            </p>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="slide-content">
          <h6 className="font-family-monts">updates</h6>
          <h1>Screaming at streaming prices</h1>

          <div className="wrapper" id="wrapper">
            <p className="activeText font-family-lato">
              Stay informed about the latest price hikes for streaming services
              like Netflix, Hulu, and Amazon Prime. Our blog offers insights
              into the reasons behind these increases and provides tips on how
              to save money while still enjoying your favorite shows and movies.
            </p>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default CarouselBlog;
